
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "terms",
  components: {},
  setup() {
    // const router = useRouter();

    const { t, te } = useI18n();

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  }
});
